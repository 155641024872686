::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  border-radius: 9px;
  background: #f1f5f6;
}

::-webkit-scrollbar-thumb {
  background: rgb(218, 220, 224);
  border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
  background: #80868b;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.LandbotPopup {
  right: 30px !important;
  /* left: 6px !important; */
}

.is-open {
  width: 420px !important;
  height: 520px !important;
  bottom: 10 !important;
  top: unset !important;
  right: 450px !important;
  left: unset !important;
  box-shadow: rgb(0 0 0 / 20%) 0px -1px 14px 0px;
  padding: 0px;
}
